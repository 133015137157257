/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Colors */
:root {
  --primera: #235b4e;
  --segunda: #bc955c;
  --tercera: #ddc9a3;
  --verde: #2a5c4d;
  --verde-claro: #e2efed;
  --gris-oscuro: #404140;
  --verde-oscuro: #10312b;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: var(--gris-oscuro);
}
/* Headers */
.title {
  font-weight: 900;
  color: var(--verde-oscuro);
  font-size: xxx-large;
}

.subtitle {
  font-weight: 700;
  color: var(--verde-oscuro);
}

.brief {
  font-weight: bolder;
}

.map-container {
  background-color: rgba(254, 251, 247, 0.8);
}

.dashboard {
  background-color: rgba(254, 251, 247, 0.8);
}

.card {
  border: 5px solid white;
  background-color: var(--verde-oscuro);
  color: white;
}

.title-card {
  color: var(--tercera);
}

/* Dots */
.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 3px solid white;
  margin: 0 5px;
  background: #c3c3c3;
}

.primera {
  background: var(--primera);
}

.segunda {
  background: var(--segunda);
}

.tercera {
  background: var(--tercera);
}

.etapa-select {
  transition: all 0.5s ease;
}

.etapa-select:hover {
  cursor: pointer;
  transform: scale(1.5);
}

.regresar {
  background-color: #ba925a;
  color: white;
}

.scrollable-content {
  max-height:500px; /* Ajusta esta altura según sea necesario */
  overflow-y: auto;
}

/* Mapa entero */

#root {
  overflow: hidden;
  background: radial-gradient(#fff, #000);
}

#map_inner svg {
  overflow: visible !important;
}

.front {
  z-index: 1000;
}

.vanish {
  display: none;
}

#map {
  filter: url(#drop-shadow);
}

/* Carousel*/

.carousel-container {
  position: relative;
}

.carousel-control-next,
.carousel-control-prev {
  z-index: 1000;
  font-weight: bold;
}

/* Semáforo */

.bg-rojo {
  background-color: #af0017;
}

.bg-naranja {
  background-color: #f2691d;
}

.bg-amarillo {
  background-color: #e1c400;
}

.bg-verde {
  background-color: #3a9839;
}

/* Location cards */

.tt_sm {
  background-clip: border-box;
  border-radius: 0.25rem;
  border: 5px solid white;
  padding: 0 1rem;
  background-color: var(--verde-oscuro);
}

.tt_custom_sm {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  color: white;
}

.tt_name_sm {
  display: none;
}
.xmark_sm {
  width: 20px;
  float: right;
  background-color: var(--tercera);
}
.tt_mobile_sm {
  display: none;
}
.location-card {
  color: white;
}

.location-card h6 {
  margin: 5px auto;
}

.location-card > div {
  margin: 1rem auto;
  font-size: smaller;
}

.liga-armonizacion {
  color: white !important;
  transition: all 0.4s ease;
}
.liga-armonizacion:hover {
  display: inline-block;
  transform: scale(1.1);
}

@media only screen and (max-width: 1024px) {
  #tt_sm_map {
    max-width: 100% !important;
  }
  .tt_sm {
    height: 500px;
    overflow-y: auto;
  }
}
